import React from "react"
import ContentSectionRichText from "./ContentSectionRichText"

import '../scss/base.scss'
import '../scss/contentSectionSplit.scss'

export default function ContentSectionSplit({columnOne, columnTwo, interleaveColumns, columnOneWidthPercent}) {

	if (interleaveColumns) {
		let longestColumn = columnOne
		if (columnOne.length < columnTwo.length) {
			longestColumn = columnTwo
		}

		return (<>
			{longestColumn.map((c, index) => (
				<div key={c.id} className="splitSection centered">
					<div style={{width: `${columnOneWidthPercent}%`}}>
						{columnOne[index] && (<>
							{columnOne[index].__typename === "ContentfulContentSectionRichText" && <ContentSectionRichText key={columnOne[index].id} description={columnOne[index].content} />}
						</>)}
					</div>
					<div style={{width: `${100 - columnOneWidthPercent}%`}}>
						{columnTwo[index] && (<>
							{columnTwo[index].__typename === "ContentfulContentSectionRichText" && <ContentSectionRichText key={columnTwo[index].id} description={columnTwo[index].content} />}
						</>)}
					</div>
				</div>
			))}
			
		</>)
	}
	else {
		return (
			<div className="splitSection centered">
				<div style={{width: `${columnOneWidthPercent}%`}}>
					{columnOne?.map(c => <>
							{c.__typename === "ContentfulContentSectionRichText" && <ContentSectionRichText key={c.id} description={c.content} />}
						</>)}
				</div>
				<div style={{width: `${100 - columnOneWidthPercent}%`}}>
					{columnTwo?.map(c => <>
							{c.__typename === "ContentfulContentSectionRichText" && <ContentSectionRichText key={c.id} description={c.content} />}
					</>)}
				</div>
			</div>
		)
	}
}
