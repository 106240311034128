import React from "react"
import SubMenuItems from './SubMenuItems'

import '../scss/base.scss'
import '../scss/subMenu.scss'

export default function SubMenu(props) {

  const menuItems = props.menuItems

  return (
		<div className="subMenu">
      <SubMenuItems menuItems={menuItems} activePage={props.activePage} />
		</div>
		)
}
