import React from "react"
import {graphql} from "gatsby"
import get from 'lodash/get'
import Layout from '../components/Layout'
import SubMenu from '../components/SubMenu'
import CenteredSection from '../components/CenteredSection'
import ContentSectionButton from '../components/ContentSectionButton'
import ContentSectionHeader from '../components/ContentSectionHeader'
import ContentSectionImageFeature from '../components/ContentSectionImageFeature'
import ContentSectionLandingPageHeader from '../components/ContentSectionLandingPageHeader'
import ContentSectionLargeImageAndText from '../components/ContentSectionLargeImageAndText'
import ContentSectionMediumImageAndText from '../components/ContentSectionMediumImageAndText'
import ContentSectionSmallImageAndText from '../components/ContentSectionSmallImageAndText'
import ContentSectionRichText from '../components/ContentSectionRichText'
import ContentSectionImageCollection from '../components/ContentSectionImageCollection'
import ContentSectionTextFeature from '../components/ContentSectionTextFeature'
import ContentSectionSplit from "../components/ContentSectionSplit"

import '../scss/header.scss'

const Page = ({data}) => {
  const page = get(data, 'contentfulPage')
  const sections = get(page, 'sections')

  return (<Layout activePage={page.slug} title={page.seoTitle} description={page.seoDescription}>

    {page.submenu ? (
      <CenteredSection extraWide={true}>
        <div className="pageWithSubmenu">
          <div className="sidebarContent">
            <SubMenu menuItems={page.submenu.menuItems} activePage={page.slug} />
          </div>
          <div className="mainPageContent">
            <h1>{page.title}</h1>
            {sections && sections.map( s => {
              if (s.__typename === "ContentfulContentSectionHeader") {
                return (<ContentSectionHeader key={s.id} title={s.title} subtitle={s.subtitle} />)
              }
              if (s.__typename === "ContentfulContentSectionTextFeature") {
                return (<ContentSectionTextFeature key={s.id} title={s.title} text={s.text} />)
              }
              if (s.__typename === "ContentfulContentSectionImageFeature") {
                return (<ContentSectionImageFeature key={s.id} name={s.name} image={s.image} link={s.link} />)
              }
              if (s.__typename === "ContentfulContentSectionLandingPageHeader") {
                throw new Error('Landing page header not currently supported in content with submenu')
              }
              if (s.__typename === "ContentfulContentSectionLargeImageAndText") {
                return (<ContentSectionLargeImageAndText key={s.id} image={s.image} imageJustify={s.imageJustify} heading={s.heading} description={s.description} displayClass={s.displayClass} link={s.link} />)
              }
              if (s.__typename === "ContentfulContentSectionMediumImageAndText") {
                return (<ContentSectionMediumImageAndText key={s.id} image={s.image} imageJustify={s.imageJustify} heading={s.heading} description={s.description} link={s.link} />)
              }
              if (s.__typename === "ContentfulContentSectionSmallImageAndText") {
                return (<ContentSectionSmallImageAndText key={s.id} image={s.image} imageJustify={s.imageJustify} heading={s.heading} description={s.description} link={s.link} />)
              }
              if (s.__typename === "ContentfulContentSectionButton") {
                return (<ContentSectionButton key={s.id} link={s.link} />)
              }
              if (s.__typename === "ContentfulContentSectionRichText") {
                return (<ContentSectionRichText key={s.id} description={s.content}  />)
              }
              if (s.__typename === "ContentfulContentSectionImageCollection") {
                return (<ContentSectionImageCollection key={s.id} images={s.images} imageSize={s.imageSize} />)
              }

              return (<div key={"Unknown Section"}>Unknown section</div>)
            })}
          </div>
        </div>
      </CenteredSection>      
    ) : (<>
      {sections && sections.map( (s, index) => {
        if (s.__typename === "ContentfulContentSectionHeader") {
          return (
            <CenteredSection key={s.id} colorStyle="dark">
              <ContentSectionHeader title={s.title} subtitle={s.subtitle} />
            </CenteredSection>
          )
        }
        if (s.__typename === "ContentfulContentSectionImageFeature") {
          return (
            <CenteredSection key={s.id}>
              <ContentSectionImageFeature name={s.name} image={s.image} link={s.link} />
            </CenteredSection>)
        }
        if (s.__typename === "ContentfulContentSectionLandingPageHeader") {
          return (<ContentSectionLandingPageHeader key={s.id} backgroundImage={s.backgroundImage} title={s.heroTitle} description={s.heroDescription} link={s.link} addDarkeningOverlay={s.addDarkeningOverlay} useDarkOverlay={s.useDarkOverlay} />)
        }
        if (s.__typename === "ContentfulContentSectionLargeImageAndText") {
          return (
            <CenteredSection key={s.id} colorStyle={s.displayClass}>
              <ContentSectionLargeImageAndText image={s.image} imageJustify={s.imageJustify} heading={s.heading} description={s.description} displayClass={s.displayClass} link={s.link} />
            </CenteredSection>
          )
        }
        if (s.__typename === "ContentfulContentSectionMediumImageAndText") {
          return (
            <CenteredSection key={s.id} colorStyle={s.displayClass ?? s.background ?? ''}>
              <ContentSectionMediumImageAndText  image={s.image} imageJustify={s.imageJustify} heading={s.heading} description={s.description} link={s.link} background={s.background} />
              {s.useDivider && sections[index+1]?.__typename === "ContentfulContentSectionMediumImageAndText" && (<div className="partitionLine"></div>)}
            </CenteredSection>
            )
        }
        if (s.__typename === "ContentfulContentSectionButton") {
          return (<ContentSectionButton key={s.id} link={s.link} />)
        }
        if (s.__typename === "ContentfulContentSectionRichText") {
          return (
            <CenteredSection key={s.id}>
              <ContentSectionRichText key={s.id} description={s.content}  />
            </CenteredSection>)
        }
        if (s.__typename === "ContentfulContentSectionImageCollection") {
          return (<ContentSectionImageCollection key={s.id} images={s.images} imageSize={s.imageSize} />)
        }
        if (s.__typename === "ContentfulContentSectionSplit") {
          return (<ContentSectionSplit key={s.id} columnOne={s.columnOne} columnTwo={s.columnTwo} interleaveColumns={s.interleaveColumns} columnOneWidthPercent={s.columnOneWidthPercent} />)
        }

        return (<div key={"UnknownSection" + index}>Unknown section</div>)
      })}
    </>)}
    
  </Layout>)
}

export default Page

export const pageQuery = graphql`
  query PageBySlug(
    $slug: String!
  ) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      seoTitle
      seoDescription
      submenu {
        id
        name
        menuItems {
          displayText
          customLink
          id
          openInNewPage
          menuSubItems {
            displayText
            customLink
            id
            openInNewPage
            menuSubItems {
              displayText
              customLink
              id
              openInNewPage
              page {
                slug
              }
            }
            page {
              slug
            }
          }
          page {
            slug
          }
        }
      }
      sections {
        __typename
        ... on ContentfulContentSectionHeader {
          id
          title
          subtitle
        }
        ... on ContentfulContentSectionImageFeature {
          id
          name
          image {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
            )
          }
        }
        ... on ContentfulContentSectionLandingPageHeader {
          id
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
          addDarkeningOverlay
          useDarkOverlay
          heroTitle
          heroDescription {
            raw
          }
          link {
            linkText
            isAButton
            openInNewTab
            page {
              slug
            }
            id
            url
          }
        }
        ... on ContentfulContentSectionLargeImageAndText {
          id
          image {
            gatsbyImageData(
              width: 450
              placeholder: BLURRED
            )
          }
          imageJustify
          heading
          description {
            raw
          }
          link {
            id
            isAButton
            linkText
            openInNewTab
            page {
              slug
            }
            url
          }
          displayClass
        }
        ... on ContentfulContentSectionMediumImageAndText {
          id
          image {
            gatsbyImageData(width: 300, placeholder: BLURRED)
          }
          imageJustify
          heading
          background
          useDivider
          description {
            raw
          }
          link {
            id
            isAButton
            linkText
            openInNewTab
            page {
              slug
            }
            url
          }
        }
        ... on ContentfulContentSectionSmallImageAndText {
          id
          imageJustify
          image {
            gatsbyImageData(width: 200, placeholder: BLURRED)
            description
            title
          }
          heading
          description {
            raw
          }
        }
        ... on ContentfulContentSectionButton {
          id
          name
          link {
            isAButton
            linkText
            page {
              slug
            }
            url
          }
        }
        ... on ContentfulContentSectionRichText {
          id
          name
          content {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                id
                contentful_id
                gatsbyImageData(width: 350, placeholder: BLURRED, quality: 80)
              }
              ... on ContentfulImage {
                __typename
                alt
                contentful_id
                caption
                display
                position
                size
                source {
                  __typename
                  id
                  description
                  contentful_id
                  gatsbyImageData(width: 1200, placeholder: BLURRED, quality: 80)
                }
              }              
            }
          }
        }
        ... on ContentfulContentSectionImageCollection {
          id
          imageSize
          images {
            contentful_id
            gatsbyImageData(width: 350, placeholder: BLURRED, quality: 80)
          }
        }
        ... on ContentfulContentSectionTextFeature {
          id
          title
          text {
            raw
          }
        }
        ... on ContentfulContentSectionSplit {
          __typename
          id
          contentful_id
          name
          interleaveColumns
          columnOneWidthPercent
          columnOne {
            __typename
            ... on ContentfulContentSectionRichText {
              id
              name
              content {
                raw
                references {
                  ... on ContentfulAsset {
                    __typename
                    id
                    contentful_id
                    gatsbyImageData(width: 350, placeholder: BLURRED, quality: 80)
                  }
                  ... on ContentfulImage {
                    __typename
                    alt
                    contentful_id
                    caption
                    display
                    position
                    size
                    source {
                      __typename
                      id
                      description
                      contentful_id
                      gatsbyImageData(width: 600, placeholder: BLURRED, quality: 80)
                    }
                  }
                }
              }
            }
          }
          
          columnTwo {
            __typename
            ... on ContentfulContentSectionRichText {
              id
              name
              content {
                raw
                references {
                  ... on ContentfulAsset {
                    __typename
                    id
                    contentful_id
                    gatsbyImageData(width: 350, placeholder: BLURRED, quality: 80)
                  }
                  ... on ContentfulImage {
                    __typename
                    alt
                    contentful_id
                    caption
                    display
                    position
                    size
                    source {
                      __typename
                      id
                      description
                      contentful_id
                      gatsbyImageData(width: 600, placeholder: BLURRED, quality: 80)
                    }
                  }
                }
              }
            }
          }    
        }

      }
    }
  }
`