import React from "react"
import {Link} from "gatsby"
import SubMenu from './SubMenu'

import '../scss/base.scss'
import '../scss/subMenu.scss'

export default function SubMenuItems(props) {

  const menuItems = props.menuItems

  return (<>
		{menuItems.filter(i => (!!i.customLink || !!i.page?.slug || !!i.menuSubItems)).map(i => <div key={i.id}>
      {/* Item has no sub items */ }
      {i.menuSubItems === null && (<>
        {i.customLink ?
          <a href={i.customLink}>
            <div className={`subMenuLink${props.activePage===i.page?.slug || props.activePage===i.customLink ? " selected" : ""}`}>{i.displayText}</div>
          </a> : (
            <Link to={`/${i.page?.slug}/`}><div className={`subMenuLink${props.activePage===i.page?.slug || props.activePage===i.customLink ? " selected" : ""}`}>{i.displayText}</div></Link>
          )}
      </>)}
      {/* Item has sub-items */ }
      {i.menuSubItems !== null && (<>
        <div className="subMenuHeader">{i.displayText}</div>
        <SubMenu menuItems={i.menuSubItems} activePage={props.activePage} />
      </>)}
    </div>)}
  </>)
}
